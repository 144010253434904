<template>
  <hr style="color: #3e989f" />

  <section class="banner-one">
    <div class="container">
      <div class="row text-data">
        <div class="text-color">
          <p class="div-text-align">Hi,</p>
          <p class="div-text-align">You are specially invited to the RxIx app.</p>
          <p class="div-text-align">
            The app equips doctor to diagnose your condition correctly and
            provide you care. It also helps you to be rightly informed from
            reliable healthcare information sources. Say bye-bye to Googling and
            to unverified social media forwards.
          </p>
          <p class="div-text-align">Get a glimpse of some of the benefits.</p>
        </div>
      </div>
    </div>
  </section>

  <section class="banner-two">
    <div class="container text-center">
      <div
        class="row img-text"
      >
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/home_mediplus.png" />
          </div>
          <p class="mt-2 text-color">
            Locate clinics near you that have adopted the RxIx app.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/medibox.png" />
          </div>
          <p class="mt-2 text-color">
            Can't visit a clinic? Schedule home visits by RxIx doctors.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/medi_text.png" />
          </div>
          <p class="mt-2 text-color">
            Your medical records are updated by doctors as part of the
            consultation.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/information.png" />
          </div>
          <p class="mt-3 text-color">
            Get consultation-aligned reliable healthcare info delivered to you.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/lib.png" />
          </div>
          <p class="mt-2 text-color">
            Access a library of patient-appropriate reliable reference
            resources.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/chat_msg.png" />
          </div>
          <p class="mt-2 text-color">
            Join patient groups. Get answers from people with similar health
            conditions.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="banner-three-text">
    <div class="container">
      <div class="row text-data">
        <div class="text-color">
          <p class="div-text-align">
            Completing the consultation, the app also drives reliable contextual
            healthcare information to patients - no more Googling or reading
            unverified into from social media platforms.
          </p>
        </div>
        <div class="text-color">
          <p class="div-text-align">
            If you have any queries, please call Rameshwar on 995 296 9922.
          </p>
        </div>
        <div class="text-color">
          <p class="div-text-align">Warm regards,</p>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.container.text-center{
  width: 60%;
  margin: auto;
}
.row.text-data{
  width: 67%;
  margin: auto;
}
.row.img-text{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
@media screen and (max-width:580px) {
  .row.text-data{
    width: 100%; margin: auto
  }
}
img.icon-img {
  width: 37%;
}
.col.img-div {
  border: 2px solid #67c0c5;
  border-radius: 20px;
  margin: 15px;
  width: 65%;
}
@media screen and (max-width: 580px) {
  .col.img-div {
    border: 2px solid #67c0c5;
    border-radius: 20px;
    margin: 15px;
    width: 100%;
  }
}
p.mt-2.text-color{
  font-size: 15px;
  text-align: left;
}
p.mt-3.text-color{
  font-size: 15px;
  text-align: left;
}
p.div-text-align{
  margin-bottom: 0.2rem;
}
.text-content {
  color: #3e99a2;
  font-size: 20px;
}
.rxix-menu li.nav-item a.nav-link {
  color: #00979e;
}
@media screen and (max-width:580px) {
section.banner-three-text{
  margin-bottom: 80px;
}
}
</style>